(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1044;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW
    .on('resize', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();

      if( WINDOW_WIDTH >= 420 ) {
        $('.vitrine-container .item .item-image img').attr('src', function() {
          return $(this).data('pre');
        });
      }
      else {
        $('.vitrine-container .item .item-image img').attr('src', function() {
          return $(this).data('pre2');
        });
      }
    })
    .trigger('resize');

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  }
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return ( WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH );
  }
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  }
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  }


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');


  if( !IS_DESKTOP_WIDTH() ) {
    $DOCUMENT
      .ready(function() {
        // Move service items to header info menu
        // --------------------------------------
        $('.header-info-menu > li:first').addClass('is-hovered').appendTo('.header-service-menu').children('ul').css('display', 'block');

        // Header menu button
        // ------------------
        $MENU_CHECKBOX
          .on('change', function() {
            if( this.checked ) {
              $BODY.addClass('menu-is-opened');
            } else {
              $BODY.removeClass('menu-is-opened');
            }
          })
          .prop('checked', false)
          .prop('disabled', false)
          .trigger('change');
      })

      // Close header menu
      // -----------------
      .on('click.closeHeaderMenu', function(e) {
        if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-service-menu').length) ) {
          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          e.stopPropagation();
        }
      })

      // Header menu items
      .on('click.headerMenu', '.header-service-menu li > div > div > a, .header-service-menu li > div > div > span, .header-service-menu li > div > .dropmarker', function(e) {
        e.preventDefault();

        var $self = $(this),
            $parent = $self.closest('li'),
            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered').children('ul').slideUp(300);

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( $self.prop("tagName").toUpperCase() === 'A' )
              location.href = $self.prop('href');
            else
              $parent.removeClass('is-hovered').children('ul').slideUp(300);
          } else {
            $parent.addClass('is-hovered').children('ul').slideDown(300);
          }
        } else {
          if( $self.prop("tagName").toUpperCase() === 'A' )
            location.href = $self.prop('href');
        }
      });
  }
  else {
    // Scrolling to top
    // ----------------
    var goTopBtn = $('#go-top-button');

    goTopBtn.click(function() {
      $WINDOW.scrollTo(0, 200);
    });

    $WINDOW.on('scroll', function(e, goTopBtnClass) {
      var scrollTop = $WINDOW.scrollTop();

      if( goTopBtnClass === undefined )
        goTopBtnClass = 'top-button-visible';

      if( scrollTop > HEADER_HEIGHT ) {
        goTopBtn.addClass(goTopBtnClass);
      } else {
        goTopBtn.removeClass();
      }
    }).trigger('scroll', ['top-button-custom-visible']);
  }


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substring($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || (IS_DESKTOP_WIDTH() ? 0 : -60);

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div > div:first-child', function(e) {
      e.preventDefault();

      var $self = $(this).parent();

      if (!$self.hasClass('active'))
        $self.addClass('active').children('div + div').slideDown(200);
      else
        $self.removeClass('active').children('div + div').slideUp(200);
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      autoFocus: false,
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    }
  });


  // Slick sliders
  // -------------
  // Main rotator
  $('.main-rotator-container').slick({
    autoplay: true,
    autoplaySpeed: 6000,
    customPaging: function() { return ''; },
    draggable: false,

    centerMode: true,
    centerPadding: '0px',
    fade: true,
    arrows: true,
    dots: true,
    slide: '.main-rotator-slide-container',
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    appendArrows: '#main-rotator-arrows',
    appendDots: '#main-rotator-dots'
  });

  // Main gallery rotator
  $('.main-gallery-block-container').each(function() {
    var $container = $(this),
        $galleries = $container.find('.main-gallery-wrapper'),
        $previews = $container.find('.main-gallery-preview');

    $galleries.each(function() {
      var $gallery = $(this);

      $gallery
        .one('init', function(e, slick) {
          if( $gallery.hasClass('active') )
            slick.options.autoplay = true;
        })
        .on('endReached', function(e, slick, currentSlide, direction) {
          // prev
          if( direction === 0 )
            $gallery.slick('last', 1);

          // next
          else if( direction === 1 ) {
            var previewIndex = ($gallery.index() === $galleries.length - 1 ? $previews.first().index() : $gallery.index() + 1);

            $previews.eq(previewIndex).trigger('click.mainGalleryPreview', [direction]);
          }
        })
        .slick({
          autoplaySpeed: 6000,
          draggable: false,
          infinite: false,
          centerMode: true,
          centerPadding: '0px',
          sideToSide: false,
          fade: true,
          arrows: true,
          slide: '.main-gallery-image',
          prevArrow: '<div class="slick-prev"></div>',
          nextArrow: '<div class="slick-next"></div>',
          appendArrows: $gallery.find('.main-gallery-controls')
        });
    });

    $previews.on('click.mainGalleryPreview', function(e, direction) {
      e.preventDefault();

      var $self = $(this);

      if( $galleries.length === 1 ) {
        if( direction === 1 )
          $galleries.slick('first', 0);
      }
      else if( !$self.hasClass('active') ) {
        $self.addClass('active').siblings('.active').removeClass('active');
        $('.main-gallery-previews-container').scrollTo($self, {duration: 350});

        $galleries
          .removeClass('active')
          .slick('pause')
          .eq($self.index())
          .addClass('active')
          .slick('goTo', 0, true)
          .slick('play');
      }
    });
  });
})();